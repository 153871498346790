@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --black: #1b1b1b;

  --white: #ffffff;
  --turquoise: #2abab9;
  --dark-blue: #1d3557;
  --light-blue: #a8dadc;
  --red: #e63946;
  --dark-gray: #343434;
  --mid-gray: #cccccc;
  --light-gray: #f7f8fb;
  --background-color: var(--light-gray);
  --orange: #f77f00;
  --green: #20c997;
  --dark-green: #146e3d;
  --dark-violet: #3d0dad;
  --shadow-default: 0 2px 4px rgba(0, 0, 0, 0.1);

  --best: #3dcf7a;
  --good: #98c369;
  --neutral: #f3c83a;
  --poor: #ec8263;
  --worst: #e12e2e;

  --height-banner: 3.75rem;
  --height-without-banner: calc(100vh - var(--height-banner));

  --border-radius: 4px;
}

body,
html {
  font-family: 'Hanken Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

a {
  color: var(--turquoise);
  text-decoration: none;
}

a:hover {
  color: var(--turquoise);
  text-decoration: underline;
  font-weight: bold;
}

button {
  font-size: 24px;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 60%;
}

button:hover {
  background-color: var(--turquoise);
  color: var(--white);
  box-shadow: none;
}

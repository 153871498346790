/* src/pages/Welcome.css */

.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Start content at the top */
  align-items: center;
  height: var(--height-without-banner);
  background-color: var(--background-color);
}

.welcome-text {
  font-size: 2.5rem;
  color: var(--black);
  margin-bottom: 3rem; /* Add space between text and button */
}

.welcome-page-buttons-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}

.training-button,
.faq-button,
.analytics-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--shadow-default);
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease;
  width: 5rem;
}

.training-button:hover,
.faq-button:hover,
.analytics-button:hover {
  transform: scale(1.05);
}

.plus-icon {
  color: var(--turquoise);
  margin-bottom: 0.5rem; /* Space between icon and text */
}

.training-button span,
.faq-button span,
.analytics-button span {
  font-size: 1.2rem;
  color: var(--black);
}

.question-icon {
  color: var(--turquoise);
  margin-bottom: 0.5rem; /* Space between icon and text */
}

.analytics-icon {
  color: var(--turquoise);
  margin-bottom: 0.5rem; /* Space between icon and text */
}

/* src/pages/Training.css */

.training-page {
  text-align: center;
  height: var(--height-without-banner);
  overflow-y: auto;
}

.training-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--shadow-default);
  max-width: 600px;
  margin: 0 auto;
}

.form-container,
.webrtc-setup {
  gap: 1rem;
  padding: 1rem;
  max-width: 800px;
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid var(--white);
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  position: relative;
}

.button-group {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
  /* Align buttons to the right */
}

.button {
  flex: 0 1 30%;
  /* Allow buttons to grow but cap their width at 30% */
  max-width: 30%;
  /* Explicitly set maximum width to 30% */
  width: auto;
  /* Prevent default width from base.css */
  text-align: center;
  /* Center text inside the button */
}

.video-preview {
  margin: 20px 0;
}

.audio-visualization {
  background-color: var(--white);
  border: 1px solid var(--mid-gray);
  border-radius: 4px;
  display: block;
  margin: 10px auto;
}

.recording-image img {
  max-width: 70%;
  max-height: 70%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.video-feed {
  position: absolute;
  /* Position relative to .form-container */
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border: 2px solid var(--turquoise);
  border-radius: 8px;
  background-color: var(--white);

  /* Flexbox for centering */
  display: flex;
  align-items: center;
  /* Vertical centering */
  justify-content: center;
  /* Horizontal centering */
}

.video-feed video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.banner {
  position: fixed; /* Fixes the banner in place */
  top: 0; /* Positions it at the top of the viewport */
  left: 0; /* Aligns it to the left edge of the viewport */
  width: 100%; /* Stretches the banner to the full width of the viewport */
  background-color: var(--white);
  display: flex;
  align-items: center;
  padding: 0 1.25rem; /* Use relative units */
  height: 3.75rem; /* Minimum height to ensure look */
  z-index: 1000; /* Ensures the banner appears above other elements */
  box-shadow: var(
    --shadow-default
  ); /* Adds a shadow to separate the banner from the content below */
}

.logo {
  max-height: 2.5rem; /* Responsive height for logo */
  width: auto; /* Maintain aspect ratio */
}

.eval_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: var(--height-without-banner);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  text-align: left;
  font-weight: bold;
  padding: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.5rem;
}

.columns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.left-column {
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
  border-radius: 8px;
}

.right-column {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
  border-radius: 8px;
}

.bottom-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.row-white {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow-default);
}

.row {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  justify-content: space-between; /* Space out the children */
  align-items: stretch; /* Force children to have the same height */
  gap: 1rem; /* Add spacing between the children */
}

.score,
.time {
  flex: 1; /* Allow both elements to grow equally */
  display: flex;
  flex-direction: row; /* Keep inner content stacked */
  justify-content: space-between; /* Space out inner content */
  align-items: center; /* Center content vertically */
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow-default);
}

.score h3,
.time h3 {
  font-size: 1rem; /* Adjust heading font size */
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.score p,
.time p {
  font-size: 1.5rem; /* Emphasize numbers */
  color: var(--text-secondary);
  margin: 0;
}

.score .icons,
.time .timer {
  margin-top: 1rem; /* Space between text and visualizations */
}

.score-text,
.time-text {
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: center; /* Vertically center within the parent */
  align-items: flex-start; /* Align text to the left */
  gap: 0.5rem; /* Add controlled spacing between h2 and h4 */
}

.score-text h2,
.score-text h4,
.time-text h2,
.time-text h4 {
  margin: 0; /* Reset default margins */
}

.icons {
  display: flex;
  justify-content: center; /* Center icons horizontally within their space */
  align-items: center; /* Center icons vertically */
  flex: 1; /* Make icons container take up the remaining space */
  gap: 0.5rem; /* Add spacing between icons */
}

.icons svg {
  display: block; /* Ensure consistent rendering */
}

.transcript-row {
  position: relative;
}

.transcript-content {
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Use flex-like layout for clamping */
  -webkit-line-clamp: 5; /* Limit to 5 lines */
  line-clamp: 5; /* Standard property for compatibility */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  text-overflow: ellipsis; /* Add ellipsis for clipped text */
  margin-bottom: 0.5rem; /* Space between content and link */
}

.transcript-content.expanded {
  -webkit-line-clamp: unset; /* Remove line clamp when expanded */
  line-clamp: unset; /* Standard property for compatibility */
  overflow: visible; /* Show all text */
}

.more-link {
  display: block; /* Force the link onto a new line */
  text-align: right; /* Align it to the left */
  font-size: 1rem; /* Adjust font size if necessary */
  color: var(--turquoise); /* Optional: add a theme color */
  cursor: pointer; /* Show pointer cursor on hover */
}

.fillword_container {
  display: flex;
  flex-direction: row; /* Ensure row layout */
  align-items: flex-start; /* Align items to the top */
  gap: 2rem; /* Add spacing between graph and counts */
  width: 100%; /* Take full container width */
  padding: 1rem;
}

.circular-progress-container {
  display: flex; /* Ensure flexbox layout for centering */
  justify-content: center;
  align-items: center;
  width: 150px; /* Set a fixed width for consistent layout */
  height: 150px; /* Set a fixed height */
  position: relative; /* For positioning SVG */
}

.fillword_counts {
  display: flex; /* Enable flexbox */
  flex-direction: row; /* Stack word counts */
  justify-content: flex-start; /* Align items to the top */
  gap: 1rem; /* Space between items */
}

.circular-progress-container svg {
  position: absolute;
  transform: rotate(-90deg); /* Rotate to start progress at the top */
}

.progress-text {
  position: absolute;
  text-align: center;
  font-size: 1rem;
  color: #ff4500;
}

.graph-row h3 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.recharts-wrapper {
  display: flex;
  justify-content: center;
}

.recharts-cartesian-grid {
  display: none; /* Remove the grid lines */
}

.progress-container {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  height: 1.5rem; /* Ensures consistent height */
  box-sizing: border-box; /* Ensures padding/margins are respected */
}

.progress-label {
  text-align: center;
  color: var(--black);
  display: flex;
  align-items: center; /* Vertically centers the text */
}

.progress-bar-wrapper {
  width: 100%;
  background-color: var(--light-gray);
  border-radius: 10px;
  overflow: hidden;
  height: 12px; /* Controls the height of the bar */
  display: flex; /* Ensures centering */
  align-items: center; /* Vertically centers the progress bar */
  margin: 0 10px; /* Adds space between the label and the value */
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease-in-out;
  border-radius: 10px;
}

.progress-value {
  text-align: right;
  color: var(--black);
  display: flex;
  align-items: center; /* Vertically centers the text */
}

/* src/pages/Login.css */

.login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--background-color);
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  /* Adjust for banner height */
  padding: 0 5%;
  gap: 5rem;
  /* Space between tagline and form */
}

.tagline {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: var(--black);
  margin-right: 2rem;
  /* Bring tagline closer to form */
}

.tagline p {
  margin: 0;
}

.highlight {
  display: inline-block;
  /* Ensures each highlighted word is treated as a separate block */
  background-color: var(--turquoise);
  color: var(--white);
  /* Text color should contrast with the highlight */
  padding: 0.1rem 0.3rem;
  /* Smaller top/bottom padding for subtle highlight */
  margin: 0.15rem 0.15rem;
  /* Small horizontal margin to avoid text crowding */
  font-weight: bold;
  /* Bold text to contrast with the highlight */
  line-height: 1.3;
  /* Slightly increased line height to give space around the highlight */
  box-decoration-break: clone;
  /* Ensures background covers each line in multiline texts */
  border-radius: 12px;
  /* Rounded corners for a softer look */
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-wrapper {
  width: 100%;
  max-width: 400px; /* same as .login-form */
}

.login-form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  max-width: 300px;
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: var(--shadow-default);
  width: 100%;
  min-width: 250px;
  max-width: 400px;
}

.login-form label {
  font-size: 1rem;
  font-weight: bold;
  color: var(--black);
  align-self: center;
}

.login-form input {
  width: 100%;
  min-width: 200px;
  padding: 0.5rem;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--black);
  background-color: var(--background-color);
}

.login-form input::placeholder {
  color: var(--dark-gray);
  opacity: 0.7;
}

.forgot-password {
  grid-column: span 2;
  text-align: right;
  font-size: 0.9rem;
  color: var(--dark-gray);
}

.login-button {
  font-size: 1.2rem;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: var(--turquoise);
}
